export const MainConfig = {
    AppDetails: {
        app_name: "TaxiSuite", 
        app_description: "TaxiSuite, aplicacion para renta de vehiculos con conductor.",
        app_identifier: "com.taxisuite.app",
        ios_app_version: "2.7", 
        android_app_version: 27
    },
    FirebaseConfig: {
        apiKey: "AIzaSyDTJdJMHL1P6AscmgUwo0W7OS52ReZ5D0I",
        authDomain: "taxisuite-167302.firebaseapp.com",
        databaseURL: "https://taxisuite-167302-default-rtdb.firebaseio.com",
        projectId: "taxisuite-167302",
        storageBucket: "taxisuite-167302.appspot.com",
        messagingSenderId: "390615652218",
        appId: "1:390615652218:web:0cf72c1f4819f8cc3da8c3",
        measurementId: "G-YMQW1C54KX"
    },
    Google_Map_Key: "AIzaSyAqinvWJIu5mQWXqCKOR5YNEK5vjseLn-k",
    facebookAppId:"677924116188147",
    PurchaseDetails: {
        CodeCanyon_Purchase_Code: "30ee13d9-aec9-4ef9-b074-0dac8c612f8f",
        Buyer_Email_Address: "fruch17@gmail.com"
    }
}